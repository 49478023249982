import { countries } from '@/constants/countries'

function getFlag(locale) {
  return countries.findIndex(country => country.code == locale)
    ? countries[countries.findIndex(country => country.code == locale)].flag
    : null
}

export const langs = [
  {
    lang: 'en',
    locale: 'en-US',
    flag: getFlag('US'),
    name: 'English'
  },
  {
    lang: 'fr',
    locale: 'fr-FR',
    flag: getFlag('FR'),
    name: 'Français'
  },
  {
    lang: 'ro',
    locale: 'ro-RO',
    flag: getFlag('RO'),
    name: 'Română'
  },
  {
    lang: 'de',
    locale: 'de-DE',
    flag: getFlag('DE'),
    name: 'Deutsch'
  },
  {
    lang: 'no',
    locale: 'nb-NO',
    flag: getFlag('NO'),
    name: 'Norsk'
  },
  {
    lang: 'fi',
    locale: 'fi-FI',
    flag: getFlag('FI'),
    name: 'Suomalainen'
  },
  {
    lang: 'sv',
    locale: 'sv-SE',
    flag: getFlag('SE'),
    name: 'Svenska'
  },
  {
    lang: 'ja',
    locale: 'ja-JP',
    flag: getFlag('JP'),
    name: '日本語'
  },
  {
    lang: 'lv',
    locale: 'lv-LV',
    flag: getFlag('LV'),
    name: 'Latviešu'
  },
  {
    lang: 'da',
    locale: 'da-DK',
    flag: getFlag('DK'),
    name: 'Dansk'
  },
  {
    lang: 'it',
    locale: 'it-IT',
    flag: getFlag('IT'),
    name: 'Italiano'
  },
  {
    lang: 'lt',
    locale: 'lt-LT',
    flag: getFlag('LT'),
    name: 'Lietuvių'
  },
  {
    lang: 'nl',
    locale: 'nl-NL',
    flag: getFlag('NL'),
    name: 'Dutch'
  },
  {
    lang: 'cs',
    locale: 'cs-CZ',
    flag: getFlag('CZ'),
    name: 'Czech'
  }
]
