export const examPreferences = {
  BIOCULAR_METHOD: 1,
  RED_GREEN_TEST: 2,
  EXAM_DURATION_DISPLAY: 3,
  CYLINDER_DISPLAY: 4,
  ACUITY_UNIT: 5,
  NEAR_VISION_OPTOTYPE: 6,
  FAR_VISION_OPTOTYPE: 7,
  EXAMINATION_DISTANCE: 8

}

export function getExamPreferencesKey(value) {
  const key = Object.keys(examPreferences).find(key => examPreferences[key] === value)
  return key
}
