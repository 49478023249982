import { auth } from '@/api/authentification'
import { organisations } from '@/api/organisations'
import router from '../../router'
import notif from '@/utils/notif'
import i18n from '../../i18n'
import utils from '@/utils/utils'

const getDefaultState = () => {
  return {
    access_token: null,
    access_token_expiration: null,
    refresh_token: null,
    refresh_token_expiration: null,
    user: {},
    tree: {},
    current_node: null,
    sessionOver: null
  }
}

const state = getDefaultState()

const getters = {
  getToken(state) {
    return {
      access_token: state.access_token,
      access_token_expiration: state.access_token_expiration,
      refresh_token: state.refresh_token
    }
  },
  getUser(state) {
    return state.user
  },
  tree(state) {
    return state.tree
  },
  current_node(state) {
    return state.current_node
  },
  getSettings(state) {
    return state.user.settings
  },
  getExamPreferences(state){
    return state.user.exam_preferences
  },
  getFeaturesFlag(state){
    return state.user.feature_flags
  }
}

const actions = {
  login({ commit }, { email, password }) {
    auth
      .login(email, password)
      .then(response => {
        let dateExpire = new Date()
        dateExpire.setSeconds(dateExpire.getSeconds() + response.data.token_expire_in)

        response.data.token_expire_in = dateExpire.toISOString()

        if (utils.isCompatibleVersion(response.data.version)) {
          commit('loginSuccess', response.data)

          let role

          if (response.data.user.change_password > 0) {
            router.push('/password/change')
          } else {
            router.push(
              sessionStorage.getItem('url') &&
                sessionStorage.getItem('url') != '/login' &&
                sessionStorage.getItem('url') != '/404'
                ? sessionStorage.getItem('url')
                : role
                ? '/dashboard' + '/' + role
                : '/dashboard'
            )
            sessionStorage.removeItem('url')
          }

          if (response.data.roles == 'Referential,SalesPerson') {
            router.push('/bds')
            alert(response.data.roles)
          }
        } else {
          notif.showNotif(i18n.messages[i18n.locale].common_client.error_version, 'error')
        }
      })
      .catch(error => {
        if (error && error.data.error === 'AUTH-001') {
          if (error.data.error === 'AUTH-001') {
            notif.showNotif(i18n.messages[i18n.locale].siview_shared.login_invalid_email_password, 'error')
          }
        } else {
          notif.showNotif('something_went_wrong', 'error')
        }
      })
  },
  logout({ dispatch }) {
    auth
      .logout()
      .then(() => {
        dispatch('resetState', null, { root: true })
        router.push('/login')
      })
      .catch(error => {
        if (error.status == 401) {
          dispatch('resetState', null, { root: true })
          router.push('/login')
        }
      })
  },
  refreshToken({ commit }, refresh_token) {
    auth.refreshToken(refresh_token).then(response => {
      let dateExpire = new Date()
      dateExpire.setSeconds(dateExpire.getSeconds() + response.data.token_expire_in)
      response.data.token_expire_in = dateExpire.toISOString()

      commit('refreshToken', response.data)
    })
  },
  updateUser({ commit }, params) {
    auth
      .updateProfil(params)
      .then(response => {
        commit('UPDATE_USER', response.data)
      })
      .catch(error => {
        if (error.data.errors) {
          Object.values(error.data.errors).forEach(errorMessage => {
            notif.showNotif(errorMessage[0], 'error')
          })
        } else if (error.data.message) {
          // == AUTH-005
          if (error.data.error == 'AUTH-005') {
            notif.showNotif(i18n.messages[i18n.locale].account_client.wrong_password, 'error')
          } else {
            notif.showNotif(error.data.message, 'error')
          }
        }
      })
  },
  forgotPassword({ dispatch }, email) {
    auth
      .forgotPassword(email)
      .then(response => {
        if (response.status === 200) {
          notif.showNotif(i18n.messages[i18n.locale].common_client.new_password_send, 'success')
        }
      })
      .catch(error => {
        if (error.data.error === 'AUTH-002') {
          notif.showNotif(i18n.messages[i18n.locale].common_client.cant_find, 'error')
        }
      })
  },
  changePassword({ commit }, { old_password, password, password_confirmation }) {
    auth
      .changePassword(old_password, password, password_confirmation)
      .then(() => {
        commit('SET_CHANGE_PASSWORD', 0)
        router.push('/')
      })
      .catch(error => {
        if (error.data.errors) {
          Object.values(error.data.errors).forEach(errorMessage => {
            notif.showNotif(errorMessage[0], 'error')
          })
        } else if (error.data.message) {
          notif.showNotif(error.data.message, 'error')
        }
      })
  },
  resetPassword({ dispatch }, { token, email, password, password_confirmation }) {
    auth
      .resetPassword(token, email, password, password_confirmation)
      .then(() => {
        notif.showNotif(i18n.messages[i18n.locale].common_client.new_password, 'success')
        router.push('/login')
      })
      .catch(error => {
        if (error.data.error === 'AUTH-003') {
          notif.showNotif(i18n.messages[i18n.locale].common_client.cant_find, 'error')
        }

        if (error.data && error.data.errors) {
          Object.values(error.data.errors).forEach(errorMessage => {
            notif.showNotif(errorMessage, 'error')
          })
        } else if (error.data && error.data.message) {
          notif.showNotif(error.data.message, 'error')
        }
      })
  },

  setRole({ commit }, param) {
    commit('SET_ROLE', param)
  },

  getTree({ commit }) {
    organisations.getTree().then(response => {
      sortChildrenAlphabetically([response.data])
      commit('SET_TREE', response.data)
    })
  },
  setCurrentNode({ commit }, param) {
    commit('SET_CURRENT_NODE', param)
  },
  setSessionOver({ commit }, param) {
    commit('SET_SESSION_OVER', param)
  },
  setChangePassword({ commit }, param) {
    commit('SET_CHANGE_PASSWORD', param)
  }
}

const mutations = {
  loginSuccess(state, data) {
    state.user = data.user
    state.access_token_expiration = data.token_expire_in
    state.permissions = data.permissions
    state.roles = data.roles
    localStorage.setItem('token', data.token)
    localStorage.setItem('refresh_token', data.refresh_token)
  },
  refreshToken(state, data) {
    state.access_token_expiration = data.token_expire_in
    localStorage.setItem('token', data.token)
    localStorage.setItem('refresh_token', data.refresh_token)
  },
  resetState(state) {
    Object.assign(state, getDefaultState())
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
  },

  SET_ROLE(state, data) {
    state.user.isOpto = data
  },
  setUser(state, data) {
    state.user = data.user
  },
  SET_TREE(state, data) {
    state.tree = data
  },
  SET_CURRENT_NODE(state, data) {
    state.current_node = data
  },
  UPDATE_USER(state, data) {
    state.user.email = data.email
    state.user.lang = data.lang
  },
  SET_SESSION_OVER(state, data) {
    state.sessionOver = data
  },
  SET_CHANGE_PASSWORD(state, data) {
    state.user.change_password = data
  }
}

function sortChildrenAlphabetically(nodes) {
  nodes.forEach(node => {
    if (node.children && node.children.length > 0) {
      node.children.sort((a, b) => a.name.localeCompare(b.name))
      sortChildrenAlphabetically(node.children)
    }
  })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
