import 'core-js/stable'
import Vue from 'vue'
import CoreuiVue from '@coreui/vue/src'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index'
import notification from 'vue-notification'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuetify from 'vuetify'

import i18n from './i18n'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
// As a plugin
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(notification)
Vue.use(CoreuiVue)
Vue.use(Vuetify)
import Maska from 'maska'
Vue.use(Maska)

//Moment js -- format date Library
import moment from 'moment'
Vue.prototype.moment = moment

// Apex charts
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import SentryRRWeb from '@sentry/rrweb'

var localeData = moment.updateLocale('fr', {
  relativeTime: {
    future: 'dans %s',
    past: '%s',
    s: '%ds',
    ss: '%ds',
    m: '%dmin',
    mm: '%dmin',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: 'm',
    MM: '%dm',
    y: '%dy',
    yy: '%dy'
  }
})
var localeData = moment.updateLocale('en', {
  relativeTime: {
    future: 'in%s',
    past: '%s',
    s: '%ds',
    ss: '%ds',
    m: '%dmin',
    mm: '%dmin',
    h: '%dh',
    hh: '%dh',
    d: '%dd',
    dd: '%dd',
    M: 'm',
    MM: '%dm',
    y: '%dy',
    yy: '%dy'
  }
})
var m = moment().fromNow()

Sentry.init({
  Vue,
  dsn: "https://e1a2fba2405949ab64e350be9d858775@sentry.oceanwork.fr/10",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['https://dev2.oceanwork.fr', 'https://my2.siviewtech.com', /^\//]
    }),
    new SentryRRWeb({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['https://dev2.oceanwork.fr', 'https://my2.siviewtech.com', /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  trackComponents: true,
  enabled: false
})

Vue.directive('remove-spaces', {
  update: function (el, binding) {
    const inputValue = el.value
    const newValue = inputValue.replace(/\s/g, '')
    if (newValue !== inputValue) {
      el.value = newValue
    }
  }
})

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: '<App/>',
  components: {
    App
  }
})
