import api from '@/api/api'
import axios from 'axios'
import Vuex from '@/store/index.js'

export const organisations = {
  createOrganisation,
  getOrganisations,
  getOrganisation,
  getTree,
  getList,
  updateOrganisation,
  search,
  deleteOrganization,
  getOrganisationsShops,
  getBrands,
  CreateClient,
  getFeatureFlags,
  getSettings

}

function getOrganisations(perPages, pages, sortBy, query, orderBy) {
  let url = `/organizations?limit=${perPages}&page=${pages}&ascending=${sortBy}&byColumn=1&orderBy=${orderBy}`
  if (Object.keys(query).length != 0) {
    url += `&query=${JSON.stringify(query)}`
  }
  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function search(name, canSearchPatient) {
  return new Promise((resolve, reject) => {
    let url = canSearchPatient
      ? `/organizations/search/${name}?withPatients=${canSearchPatient}`
      : `/organizations/search/${name}`
    api
      .get(url)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function createOrganisation(organisation) {
  return new Promise((resolve, reject) => {
    api
      .post('/organizations', organisation)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getOrganisation(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/organizations/${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getTree() {
  return new Promise((resolve, reject) => {
    api
      .get(`/organizations/tree`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getList(type = null) {
  return new Promise((resolve, reject) => {
    let queryParam;

    queryParam = "";
    if (type) {
      queryParam = "?type=" + type
    }
    api
      .get(`/organizations/list`+ queryParam)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function updateOrganisation(organisation) {
  return new Promise((resolve, reject) => {
    api
      .put(`/organizations/${organisation.id}`, organisation)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function deleteOrganization(organisation) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/organizations/${organisation.id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getOrganisationsShops(id, perPages, pages, sortBy, query, orderBy) {
  let url = `/organizations/${id}/shops-available?limit=${perPages}&page=${pages}&ascending=${sortBy}&byColumn=1&orderBy=${orderBy}`
  if (Object.keys(query).length != 0) {
    url += `&query=${JSON.stringify(query)}`
  }
  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getBrands() {
  return new Promise((resolve, reject) => {
    api
      .get(`/organizations/brands`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function CreateClient(id) {
  return new Promise((resolve, reject) => {
    api
      .get('/organizations/'+id+'/create-client')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

function getFeatureFlags(id){
  return new Promise((resolve, reject) => {
    api
      .get('/organizations/'+id+'/feature-flags')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}


function getSettings(id){
  return new Promise((resolve, reject) => {
    api
      .get('/organizations/'+id+'/settings')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}