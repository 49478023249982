export const featuresFlag = {
  VIRTUAL_CONSOLE: 1,
  ENABLE_QUICK_CHECK: 2,
  SIMPLIFIED_REPORT: 3,
  BINOCULAR_EXAM: 4,
  AVAILABLE_LANGUAGES: 5,
  FAR_VISION_OPTOTYPES: 6
}

export function getFeaturesFlagKey(value) {
  const key = Object.keys(featuresFlag).find(key => featuresFlag[key] === value)
  return key
}
